var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.$t("cip.dc.transformation.title.name"),
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add": _vm.addFn, "head-romve": _vm.deleteFn },
      }),
      _c(
        "el-container",
        { staticClass: "new-sino-box" },
        [
          _c(
            "el-header",
            {
              staticClass: "new-sino-header search-form",
              staticStyle: { height: "56px" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    height: "100%",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: _vm.$t(
                        "cip.dc.transformation.msg.placeholder"
                      ),
                      size: "mini",
                    },
                    model: {
                      value: _vm.queryList.param1,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryList, "param1", $$v)
                      },
                      expression: "queryList.param1",
                    },
                  }),
                  _c(
                    "el-button-group",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.onLoad2 },
                      }),
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          icon: "reset-refresh icon-refresh",
                        },
                        on: { click: _vm.czFn },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-main",
            { staticClass: "new-sino-main" },
            [
              _c("grid-layout", {
                ref: "gridLayout",
                attrs: {
                  "data-total": _vm.total,
                  page: _vm.page,
                  tableOptions: _vm.tableOptions,
                  tableData: _vm.tableData,
                  tableLoading: _vm.tableLoading,
                  gridRowBtn: _vm.gridRowBtn,
                },
                on: {
                  "page-size-change": _vm.handleSizeChange,
                  "page-current-change": _vm.handleCurrentChange,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.handleSelectionChange,
                  "grid-edit": _vm.handleEdit,
                  "grid-del": _vm.deleteFn2,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }